import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				I nostri servizi | Esplora i nostri servizi su Auto Revival
			</title>
			<meta name={"description"} content={"Eccellenza artigianale, restauro delle leggende!"} />
			<meta property={"og:title"} content={"I nostri servizi | Esplora i nostri servizi su Auto Revival"} />
			<meta property={"og:description"} content={"Eccellenza artigianale, restauro delle leggende!"} />
			<meta property={"og:image"} content={"https://hovireluxpro.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://hovireluxpro.com/img/4422492.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://hovireluxpro.com/img/4422492.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://hovireluxpro.com/img/4422492.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://hovireluxpro.com/img/4422492.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://hovireluxpro.com/img/4422492.png"} />
			<meta name={"msapplication-TileImage"} content={"https://hovireluxpro.com/img/4422492.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				La nostra officina
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 180px 0px 180px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				Auto Revival offre una serie di servizi specializzati progettati per soddisfare le esigenze uniche degli appassionati di auto d'epoca. La nostra esperienza spazia da restauri meticolosi a modifiche personalizzate, assicurando che ogni auto d'epoca non solo funzioni senza problemi, ma mantenga anche la sua essenza storica. Esplorate i nostri servizi per scoprire come possiamo aiutarvi a far rivivere e personalizzare la vostra auto d'epoca.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://hovireluxpro.com/img/3.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Restauro completo del veicolo
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Ricostruzione del motore: Ripristino della potenza con precisione per garantire le massime prestazioni.
							<br />
Lavori di carrozzeria: Dalla riparazione delle ammaccature al restauro completo della carrozzeria, riportiamo l'aspetto e la sensazione originali.
							<br />
Ristrutturazione degli interni: Tappezzerie e impianti restaurati o ricreati in modo da corrispondere alle specifiche originali.

						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://hovireluxpro.com/img/4.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Potenziamento delle prestazioni
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Messa a punto del motore: Miglioramento dell'efficienza e della potenza del motore nel rispetto del carattere classico.
							<br />
							<br />
Aggiornamenti delle sospensioni: Miglioramento della qualità di guida e della maneggevolezza, su misura per ogni veicolo.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://hovireluxpro.com/img/5.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
						Manutenzione preventiva
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
						Controlli regolari: Servizi programmati per mantenere il vostro classico in condizioni ottimali.
							<br />
							<br />
Cambio dei fluidi e dei filtri: Utilizzo di oli e filtri di alta qualità per garantire longevità e prestazioni.

						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://hovireluxpro.com/img/6.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Auto Revival
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white" width="50%">
				Pronti a migliorare il vostro classico?
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base" width="50%">
				La vostra auto d'epoca merita il meglio: lasciateci dare vita alla vostra visione!
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					background="--color-dark"
					padding="12px 28px 12px 28px"
				>
					Contattaci
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});